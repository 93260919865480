import tasks from '@/modules/road-maintenance-module/tasks/routes/index.child'
import task from '@/modules/road-maintenance-module/tasks/routes/show.child'
import reports from '@/modules/road-maintenance-module/reports/routes/index.child'
import ActiveWorkOrders from '@/modules/road-maintenance-module/patrol-service/routes/active.child'
import InactiveWorkOrders from '@/modules/road-maintenance-module/patrol-service/routes/inactive.child'
import ExcavationActiveWorkOrders from '@/modules/road-maintenance-module/excavation-service/routes/active.child'
import ExcavationInactiveWorkOrders from '@/modules/road-maintenance-module/excavation-service/routes/inactive.child'
import ExcavationStakeholders from '@/modules/road-maintenance-module/excavation-settings/routes/stakeholders.child'
import ExcavationResponsiblePersonOfTheInvestor from '@/modules/road-maintenance-module/excavation-settings/routes/responsible_person_of_the_investor.child'
import ExcavationResponsiblePersonOfZagrebRoads from '@/modules/road-maintenance-module/excavation-settings/routes/responsible_person_of_zagreb_roads.child'
import ExcavationQuarters from '@/modules/road-maintenance-module/excavation-settings/routes/quarters.child'
import ExcavationDistrictOffices from '@/modules/road-maintenance-module/excavation-settings/routes/district_offices.child'
import ExcavationRoadCategories from '@/modules/road-maintenance-module/excavation-settings/routes/road_categories.child'
import ExcavationPurposesOfExcavations from '@/modules/road-maintenance-module/excavation-settings/routes/purposes_of_excavation.child'
import patrolVehicleServiceWorkOrders from '@/modules/road-maintenance-module/patrol-vehicle-service/routes/index.child'
import TrafficLightsIndex from '@/modules/road-maintenance-module/traffic-lights/routes/index.child'
import PatrolSubscriptionSettings from '@/modules/road-maintenance-module/patrol-settings/subscriptions/routes/index.child'
import PrpIndex from '@/modules/road-maintenance-module/prp/routes/index.child'

export default {
  path: '/road-maintenance',
  redirect: {
    name: 'NotFound',
    params: ['/road-maintenance']
  },
  component: () => import(/* webpackChunkName: "administration" */ '../views/RoadMaintenanceView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    tasks,
    task,
    reports,
    ActiveWorkOrders,
    InactiveWorkOrders,
    patrolVehicleServiceWorkOrders,
    ExcavationActiveWorkOrders,
    ExcavationInactiveWorkOrders,
    ExcavationStakeholders,
    ExcavationResponsiblePersonOfTheInvestor,
    ExcavationResponsiblePersonOfZagrebRoads,
    ExcavationQuarters,
    ExcavationDistrictOffices,
    ExcavationRoadCategories,
    ExcavationPurposesOfExcavations,
    TrafficLightsIndex,
    PatrolSubscriptionSettings,
    PrpIndex
  ]
}
