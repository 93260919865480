import { api } from '@/global/services/api'
import L from 'leaflet'

const commonActiveIconDataObject = {
  iconSize: [12, 12],
  iconAnchor: [6, 6]
}

const state = {
  items: [],
  dropDownValues: {},
  permissions: [],
  mapItems: [],
  cityDistricts: [],
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 5,
    totalPages: 1,
    total: 0
  },
  active: true,
  table: false,
  statuses: [],
  from: 0,
  to: 0,
  address: '',
  consentNumber: '',
  year: '',
  sort: {},
  filters: [],
  icons: {
    green: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'green_icon circle'
    }),
    red: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'red_icon circle'
    })
  }
}

const getters = {
  list: state => state.items,
  permissions: state => state.permissions,
  pagination: state => state.pagination,
  icons: state => state.icons,
  filters: state => state.filters,
  mapItems: state => state.mapItems,
  cityDistricts: state => state.cityDistricts,
  sort: state => state.sort,
  dropDownValues: state => state.dropDownValues
}

const actions = {

  async getIcons ({ commit, state }, options) {
    try {
      const params = {
        from: options.from,
        to: options.to,
        status: options.status,
        consentNumber: options.consentNumber ?? '',
        address: options.address ?? '',
        year: options.year ?? ''
      }

      const {
        data
      } = await api('road-maintenance').get('temporary-traffic-regulation/icons', params)
      commit('SET_MAP_ITEMS', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        filters: options.filters,
        sort: options.sort
      }

      const {
        data,
        pagination,
        sort
      } = await api('road-maintenance').get('temporary-traffic-regulation', params)

      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchCityDistricts ({ commit, state }, options) {
    try {
      const { data } = await api('locations').get('locations/city-districts', { withChildren: true })
      commit('SET_CITY_DISTRICTS', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchDropDownValues ({ commit, dispatch }) {
    try {
      const data = await api('road-maintenance').get('excavation-order/tabs-drop-down-values')

      commit('SET_DROPDOWN_VALUES', data)
    }
    catch (e) {
      console.log(e)
    }
  },

  async fetchPermissions ({ commit, dispatch }) {
    try {
      const permissions = await api('road-maintenance').get('excavation-service/permissions')

      commit('SET_PERMISSIONS', permissions)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {
  SET_ITEMS: (state, payload) => (state.items = payload),
  SET_CITY_DISTRICTS: (state, payload) => (state.cityDistricts = payload),
  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload
    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },
  SET_SORT: (state, payload) => (state.sort = payload),
  SET_FILTERS: (state, payload) => (state.filters = payload),
  SET_MAP_ITEMS: (state, payload) => (state.mapItems = payload),
  SET_DROPDOWN_VALUES: (state, payload) => (state.dropDownValues = payload),
  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
