import Vue from 'vue'
import axios from 'axios'
import NotFoundError from './NotFoundError'
import moduleList from '@/global/moduleList'
import { has } from 'lodash/object'
import { notify } from '@/global/plugins/error-reporting'
import { serialize } from 'object-to-formdata'
import { isEmpty } from 'lodash'

// Intercept axios requests
axios.interceptors.response.use(
  response => response,
  error => {
    // Reload the page when the response status code is 401.
    if (error.response?.status === 401) window.location.reload()

    return Promise.reject(error)
  }
)

let vuexStore = null

/**
 * @class
 * @constructor
 * @public
 */
class Api {
  constructor (module = '') {
    this.basePath = '/api'
    this.module = module

    return new Proxy(this, {
      get: function (api, field) {
        if (field in api) return api[field]

        if (moduleList.indexOf(field) !== -1 && api.module === '') return new Api(field)

        return api
      }
    })
  }

  async get (path, params, config) {
    return await this._request('GET', path, params, config)
  }

  async post (path, params, config = {}) {
    return await this._request('POST', path, params, config)
  }

  async patch (path, params) {
    return await this._request('PATCH', path, params)
  }

  async delete (path, params) {
    return await this._request('DELETE', path, params)
  }

  async _request (method, path, data, axiosConfig = {}) {
    let response = null
    const config = this._prepareConfig(method, path, data, axiosConfig)

    try {
      const { data: fetchedData } = await axios.request(config)
      response = fetchedData

      const { message = '' } = response
      this._pushNotification(message)
    }
    catch (e) {
      const extendedError = this._extendError(e)

      this._handleError(extendedError)

      throw extendedError
    }

    return response
  }

  _prepareConfig (requestMethod, path, formData, axiosConfig = {}) {
    path = (path || '').trim().replace(/^\/|\/$/g, '')

    const paths = ([this.module, path]).join('/')

    const { data, method } = this._prepareData(formData, requestMethod)

    const config = {
      ...axiosConfig,
      method,
      url: this.basePath + '/' + paths,
      maxRedirects: 0,
      withCredentials: true
    }

    config[method === 'GET' ? 'params' : 'data'] = data

    return config
  }

  _prepareData (data, method) {
    if (data && Object.values(data).some((item) => item instanceof File)) {
      if (['PUT', 'PATCH'].includes(method)) {
        data._method = method
        method = 'POST'
      }
      return { data: serialize(data, { indices: true, allowEmptyArrays: false }), method }
    }
    return { data, method }
  }

  _extendError (error) {
    const status = error.response?.status
    const isCancelled = error.code === 'ERR_CANCELED'

    // Error classes
    error.isClientError = status && status >= 400 && status <= 499
    error.isServerError = status && status >= 500
    error.isNetworkError = has(error, 'request') && !has(error, 'response')
    error.isApplicationError = !isCancelled && !has(error, 'request') && !has(error, 'response')

    // Client errors (4xx)
    error.isForbiddenError = error.isClientError && status === 403
    error.isValidationError = error.isClientError && status === 422
    error.isTooManyRequestsError = error.isClientError && status === 429

    // Server errors (5xx)
    error.isMaintenanceMode = error.isServerError && status === 503

    error.getValidationErrors = () => !error.isValidationError ? {} : error.response.data.errors

    return error
  }

  _handleError (error) {
    switch (true) {
      case error.isForbiddenError:
        break

      case error.isApplicationError:
        this._handleApplicationError()
        break

      case error.isValidationError:
        this._handleValidationError(error)
        break

      case error.isClientError:
        this._handleClientError(error)
        break

      case error.isMaintenanceMode:
        this._handleMaintenanceMode()
        break

      case error.isNetworkError:
        this._handleNetworkError()
        break

      case error.isServerError:
      case error.isTooManyRequestsError:
        this._handleServerError(error)

        // TODO: if 401 redirect to login
        break
    }
  }

  _pushNotification (message) {
    if (message.trim() !== '') {
      vuexStore.dispatch('base/notifications/push', message)
    }
  }

  _handleApplicationError () {
    this._pushNotification(Vue.prototype.$t('base.application_error'))
  }

  _handleClientError (error) {
    if (error?.response?.data?.message) {
      this._pushNotification(error?.response?.data?.message)
    }
  }

  _handleNetworkError () {
    this._pushNotification(Vue.prototype.$t('base.network_error'))
  }

  _handleMaintenanceMode () {
    localStorage.setItem('LAST_URL', window.location)
    window.location = '/maintenance-mode'
  }

  _handleServerError (error) {
    this._pushNotification(Vue.prototype.$t('base.server_error'))

    if (error.response.status === 404) {
      notify(new NotFoundError('Got 404 from server!'))
    }
  }

  _handleValidationError (error) {
    const validationErrors = error.getValidationErrors()
    let message = error?.response?.data?.message ? error?.response?.data?.message : Vue.prototype.$t('base.validation_error')
    if (validationErrors && typeof validationErrors === 'object' && !Array.isArray(validationErrors) && !isEmpty(validationErrors)) {
      message = Vue.prototype.$t('base.validation_errors_message') + '<br>' + Object.entries(validationErrors).map(([, errors]) => {
        if (Array.isArray(errors)) {
          return errors.join(', ')
        }
        else {
          return errors
        }
      }).join('<br>')
    }
    this._pushNotification(message)
  }
}

export function init (store) {
  vuexStore = store
}

export function api (module) {
  return module ? new Api(module) : new Api()
}
