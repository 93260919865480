<template>
  <div>
    <v-text-field
      v-if="showInput"
      v-model="selectedVehicleNames"
      :label="formField ? '' : label"
      :prepend-inner-icon="prependIcon"
      :append-icon="formField ? 'mdi-car-multiple' : ''"
      :error-messages="errorMessages"
      readonly
      :outlined="outlined || formField"
      :dense="dense || formField"
      @mousedown.stop="dialog = true"
    />
    <v-card v-if="selectedVehicles.length && showList">
      <v-treeview
        :items="vehicleTreeForDisplayList"
        hoverable
        activatable
        open-on-click
      >
        <template #prepend="{ item }">
          <v-icon v-if="item.children && item.children.length > 0">
            {{ 'mdi-car-multiple' }}
          </v-icon>
          <v-icon
            v-else
            @click="$emit('list-vehicle-label-clicked', item)"
          >
            {{ 'mdi-car' }}
          </v-icon>
        </template>
        <template
          #label="{ item }"
        >
          <div @click="$emit('list-vehicle-label-clicked', item)">
            <div class="font-weight-bold">
              {{ item.registration ? item.registration : '' }}
              {{ String(item.id).includes('group-') ? item.label : '(' + item.label + ')' }}
            </div>
            <span
              v-if="item.driver"
              class="flex-fill"
            >
              - {{ item.driver }}
            </span>
          </div>
        </template>
        <template
          #append="{ item }"
          class="col text-right"
        >
          <v-btn
            icon
            @click="removeItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="30em"
      scrollable
      @keydown.enter.prevent="closeDialog"
      @keydown.esc.prevent="closeDialog"
    >
      <v-card
        :loading="controlsLoading"
      >
        <v-row
          class="flex ma-0"
          :class="[title ? 'justify-space-between' : 'justify-end']"
        >
          <v-card-title v-if="title">
            {{ title }}
          </v-card-title>

          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>

        <v-card-subtitle v-if="subtitle">
          {{ subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            :clearable="clearSearchButton"
          />
          <checkbox-list-menu
            class="mr-0 my-1 justify-end"
            :items="tags"
            :menu-title="$t('fleet.vehicle_tags')"
          />
          <v-row
            v-if="Object.values(selectedTags).length"
            class="tags-container"
          >
            <template v-for="item in tags">
              <v-chip
                v-if="item.value"
                :key="item.text"
                :value="item.value"
                :color="`${getRandomColor(item, 'id')} lighten-3`"
                class="ma-1"
                active-class="primary--text"
                close-icon="mdi-close"
                close
                small
                filter
                @click:close="item.value = false"
              >
                {{ item.text }}
              </v-chip>
            </template>
          </v-row>
          <v-row
            v-if="!maxSelect"
            class="mb-3"
          >
            <v-col class="py-0">
              <v-checkbox
                v-model="selectAll"
                :label="$t('base.select_all')"
                color="accent"
                hide-details
              />
            </v-col>
          </v-row>
          <v-treeview
            v-model="selectedVehicles"
            :items="vehicleTree"
            :item-text="vehicleIdentifier"
            item-key="id"
            return-object
            selectable
            open-on-click
            activatable
            :search="search"
            :filter="vehiclesFilter"
            :active="active"
            :value="['group-1']"
            @update:active="selectOnActive"
          >
            <template
              #label="{ item }"
            >
              <span v-if="item.registration"> {{ item.registration }}</span>
              <span> {{ String(item.id).includes('group-') ? item.label : '(' + item.label + ')' }} </span>
              <span v-if="item.driver"> - {{ item.driver }}</span>
            </template>
          </v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="closeDialog"
          >
            {{ confirmSelection }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isEmpty, isEqual } from 'lodash'
import { api } from '@/global/services/api'
import store from '@/global/store'
import withoutWatchersMixin from '@/global/mixins/withoutWatchersMixin'
import CheckboxListMenu from '@/global/components/lists/CheckboxListMenu'
import randomColorsMixin from '@/global/mixins/randomColorsMixin'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersBase,
  mapActions: mapActionsBase
} = createNamespacedHelpers('base/config')
const {
  mapGetters: mapGettersTracking,
  mapActions: mapActionsTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'VehiclePicker',

  components: {
    CheckboxListMenu
  },

  mixins: [
    randomColorsMixin,
    withoutWatchersMixin
  ],

  props: {
    workMachinesOnly: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: function () {
        return this.$t('satellite-tracking/live-tracking.vehicles')
      }
    },
    confirmSelection: {
      type: String,
      default: function () {
        return this.$t('base.ok')
      }
    },
    prependIcon: {
      type: String,
      default: 'mdi-car-multiple'
    },
    trackersOnly: {
      type: Boolean,
      default: false
    },
    showList: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: true
    },
    showPicker: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    startOpen: {
      type: Boolean,
      default: false
    },
    formField: {
      type: Boolean,
      default: false
    },
    maxSelect: {
      type: Number,
      default: null
    },
    scopeId: {
      type: Number,
      default: null
    },
    updateTraccarToken: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    preselectedVehicles: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    viewConfig: {
      type: Object,
      default: () => ({})
    },
    showVehiclesWithAssignments: {
      type: Boolean,
      default: false
    },
    canBusOnly: {
      type: Boolean,
      default: false
    },
    fuelProbesOnly: {
      type: Boolean,
      default: false
    },
    temperatureSensorsOnly: {
      type: Boolean,
      default: false
    },
    activeVehicles: {
      type: Boolean,
      default: false
    },
    activeTemperatureSensorsOnly: {
      type: Boolean,
      default: false
    },
    refreshVehicles: {
      type: Boolean,
      default: false
    },
    chosenTemperatureSensors: {
      type: Array,
      default: null
    },
    useOldLogic: {
      type: Boolean,
      default: true
    }

  },

  data () {
    return {
      tree: [],
      dialog: false,
      selectAll: false,
      search: null,
      active: [],
      vehicles: [],
      vehicleTree: [],
      selectedVehicles: [],
      vehicleIdentifier: 'label',
      tags: []
    }
  },

  computed: {
    ...mapGettersBase({
      isCompanyScopeUpdated: 'isCompanyScopeUpdated'
    }),
    ...mapGettersTracking({ controlsLoading: 'controlsLoading' }),

    vehiclesFilter () {
      // Filter doesn't get triggered when search is empty, problem with Vuetify treeview component
      // same issue has been reported on github and is not fixed yet: https://github.com/vuetifyjs/vuetify/issues/10815
      // workaround is to just add blank space to search when tag is present
      return (vehicle, search) => {
        const vehicleName = `${vehicle.registration} (${vehicle[this.vehicleIdentifier]}) - ${vehicle.driver}`
        const selectedTagIds = Object.keys(this.selectedTags)
        const trimmedSearch = search.toLowerCase().trim()

        if (selectedTagIds.length > 0) {
          if (vehicle.tags) {
            return vehicleName.toLowerCase().includes(trimmedSearch) &&
              this.elementContainsTag(selectedTagIds, vehicle)
          }
          else {
            return false
          }
        }
        else {
          return vehicleName.toLowerCase().includes(trimmedSearch)
        }
      }
    },

    // filter vehicle tree to show the list only for selected vehicles, also grouped
    vehicleTreeForDisplayList () {
      return this.buildVehicleTree(this.selectedVehicles)
    },

    // Display human-readable names instead of vehicle IDs
    selectedVehicleNames () {
      return this.selectedVehicles
        .map(vehicle => `${vehicle.registration} (${vehicle[this.vehicleIdentifier]})`)
        .join(', ')
    },

    selectedTags () {
      const selected = {}
      for (let i = 0; i < this.tags.length; i++) {
        const tag = this.tags[i]
        if (tag.value) {
          selected[tag.id] = tag.value
        }
      }

      return selected
    },

    clearSearchButton () {
      if (this.search?.trim().length > 0) {
        return true
      }
      else {
        return false
      }
    }
  },

  watch: {
    showPicker: {
      immediate: true,
      handler (showPicker) {
        this.dialog = showPicker
      }
    },

    dialog (value) {
      // Apply model changes after the dialog is closed
      if (this.useOldLogic === false) {
        if (value === false) {
          this.$emit('input', this.selectedVehicles)
        }
        else {
          this.$withoutWatchers(() => {
            // eslint-disable-next-line vue/no-mutating-props
            this.selectedVehicles = this.value
          })
          this.getVehicles()
        }
      }
      else {
        if (value === false) {
          this.$emit('input', this.selectedVehicles)
        }
      }
      this.$emit('dialog-open-status', value)
    },

    value () {
      this.populateSelectedVehicles()
    },

    selectAll (value) {
      if (value) {
        this.selectedVehicles = this.vehicles
      }
      else if (this.selectedVehicles.length === this.vehicles.length) {
        this.selectedVehicles = []
      }
    },

    selectedVehicles (newValue) {
      if (this.maxSelect && newValue.length > this.maxSelect) {
        this.$withoutWatchers(() => {
          // Remove first array item if max limit is set to 1 and user try to select another item
          if (this.maxSelect === 1) {
            this.selectedVehicles.shift()
          }
          else {
            this.selectedVehicles = this.selectedVehicles.slice(0, this.maxSelect)
            store.dispatch('base/notifications/push', this.$t('satellite-tracking/history.selection_limit') + ' ' + this.maxSelect)
          }
        })
      }
      else if (this.maxSelect === 0 && newValue && this.useOldLogic === false) {
        this.$withoutWatchers(() => {
          this.selectedVehicles = newValue
        })
      }

      if (this.selectedVehicles.length !== this.vehicles.length) {
        this.selectAll = false
      }

      if (this.selectedVehicles.length === this.vehicles.length) {
        this.selectAll = true
      }

      this.$emit('has-selected-vehicles', !!newValue.length)
    },

    selectedTags () {
      if (!this.search && this.checkTagSelected()) {
        this.search = ' '
      }
    },

    search (search) {
      if (!search && this.checkTagSelected()) {
        this.search = ' '
      }
    },

    scopeId: async function () {
      await this.resetVehicles()
    },

    isCompanyScopeUpdated: async function () {
      await this.resetVehicles()
      this.unsetCompanyScopeUpdated()
    },

    activeTemperatureSensorsOnly () {
      this.resetVehicles()
    },

    refreshVehicles (refreshVehicles) {
      if (refreshVehicles) {
        this.resetVehicles()
        this.$emit('reset-refresh-vehicles')
      }
    },
    fuelProbesOnly (fuelProbesOnly) {
      if (fuelProbesOnly) {
        this.getVehicles()
      }
    },
    canBusOnly (canBusOnly) {
      if (canBusOnly) {
        this.getVehicles()
      }
    }
  },
  async created () {
    // await new Promise(resolve => setTimeout(resolve, 3_000))
    // TODO: investigate how this will function when a large amount of vehicles is present
    await this.getVehicles()
  },
  async mounted () {
    this.dialog = this.startOpen || this.showPicker
  },
  methods: {
    ...mapActionsBase([
      'unsetCompanyScopeUpdated'
    ]),
    ...mapActionsTracking(['setControlsLoading']),

    isEmpty,

    closeDialog () {
      this.dialog = false
      setTimeout(() => {
        this.clearSearch()
      }, 250)
    },

    clearSearch () {
      if (this.checkTagSelected()) {
        this.search = ' '
      }
    },

    async resetVehicles () {
      this.selectedVehicles = []
      await this.getVehicles()
    },

    checkTagSelected () {
      return Object.keys(this.selectedTags).length > 0
    },

    elementContainsTag (selectedTagIds, element) {
      if (!selectedTagIds.length) {
        return true
      }

      return selectedTagIds
        .filter(id => (Object.keys(element.tags).indexOf(id) !== -1))
        .length > 0
    },

    removeItem (item) {
      if (item.children) {
        const vehiclesToBeRemoveIds = item.children.map(vehicle => vehicle.id)
        this.selectedVehicles = this.selectedVehicles.filter(vehicle => !vehiclesToBeRemoveIds.includes(vehicle.id))
        this.$emit('input', this.selectedVehicles)
      }
      this.selectedVehicles = this.selectedVehicles.filter(vehicle => vehicle.id !== item.id)
      this.$emit('input', this.selectedVehicles)
    },

    async getVehicles () {
      const companyScopeid = this.scopeId
      this.setControlsLoading(true)
      const route = '/vehicles/picker-options'

      const searchParams = {}
      if (this.trackersOnly) searchParams.trackers_only = 1
      if (this.workMachinesOnly) searchParams.work_machines = 1
      if (this.canBusOnly) searchParams.can_bus_vehicles = 1
      if (this.fuelProbesOnly) searchParams.fuel_probe_vehicles = 1
      if (this.temperatureSensorsOnly) searchParams.temperature_sensor_vehicles = 1
      if (this.activeTemperatureSensorsOnly) searchParams.active_temperature_sensor_vehicles = 1
      if (this.showVehiclesWithAssignments) searchParams.report_vehicles = 1
      if (this.activeVehicles) searchParams.active_vehicles = 1

      const response = await api('fleet').get(
        route,
        {
          ...searchParams,
          ...companyScopeid && { company_scope_id: companyScopeid }
        }
      )
      this.vehicles = response.data.vehicles
      this.tags = response.data.tags

      this.$emit('fetch-input-types', response.data.input_types)

      if (this.preselectedVehicles && this.preselectedVehicles.length) {
        this.selectedVehicles = this.vehicles.filter(vehicle => {
          return this.preselectedVehicles.includes(vehicle.id)
        })
        this.$emit('input', this.selectedVehicles)
      }

      this.populateSelectedVehicles()
      this.vehicleTree = this.buildVehicleTree(this.vehicles)
      this.setControlsLoading(false)
      if (this.useOldLogic === false) {
        this.selectedVehicles = this.value
      }
    },

    // Goes through the list of vehicles and builds a group-based tree for the treeview component
    buildVehicleTree (vehicles) {
      const groups = []
      const independent = []

      for (const vehicle of vehicles) {
        if (this.activeTemperatureSensorsOnly) {
          vehicle.children = []
          vehicle.tracker?.active_temperature_sensors?.forEach(temperatureSensorAssignment => {
            vehicle.children.push({
              id: 'sensorId-' + temperatureSensorAssignment.temperature_sensor?.id,
              label: temperatureSensorAssignment.temperature_sensor?.name,
              registration: vehicle.registration,
              vehicleId: vehicle.id
            })
          })
        }
        if (vehicle.group) {
          let group = groups.find(group => group.id === 'group-' + vehicle.group.id)
          if (!group) {
            group = {
              id: 'group-' + vehicle.group.id,
              [this.vehicleIdentifier]: vehicle.group.name,
              children: []
            }
            groups.push(group)
          }
          group.children.push(vehicle)
        }
        else {
          independent.push(vehicle)
        }
      }
      return [
        ...groups,
        ...independent
      ]
    },

    selectOnActive ([vehicle]) {
      if (vehicle) {
        // Don't actually allow the user to mark vehicles as active
        this.active = []
        // Toggle the selected status of the activated vehicle
        if (this.selectedVehicles.includes(vehicle)) {
          if (this.useOldLogic === false) {
            this.$withoutWatchers(() => {
              this.selectedVehicles.splice(this.selectedVehicles.indexOf(vehicle), 1)
            })
          }
          else {
            this.selectedVehicles.splice(this.selectedVehicles.indexOf(vehicle), 1)
          }
        }
        else {
          if (this.useOldLogic === false) {
            this.$withoutWatchers(() => {
              this.selectedVehicles.push(vehicle)
            })
          }
          else {
            this.selectedVehicles.push(vehicle)
          }
        }
      }
    },

    populateSelectedVehicles () {
      if (!this.value) {
        this.selectedVehicles = []
      }
      else {
        if (this.activeTemperatureSensorsOnly && this.chosenTemperatureSensors) {
          this.selectedVehicles = this.chosenTemperatureSensors.map(data => ({
            id: 'sensorId-' + data.sensorId,
            label: this.vehicles.find(vehicle => vehicle.id === data.vehicleId)?.tracker?.active_temperature_sensors
              ?.find(assignment => assignment.ts_id === data.sensorId)?.temperature_sensor?.name,
            registration: this.vehicles.find(vehicle => vehicle.id === data.vehicleId)?.registration,
            vehicleId: data.vehicleId
          }))
        }
        else {
          this.selectedVehicles = this.vehicles.filter(vehicle => {
            return this.value.some(value => {
              // for cases where value is vehicle object
              if (isEqual(value, vehicle)) {
                return true
              }
              else {
                // cases where value is just vehicle id
                if (isEqual(value, vehicle.id)) {
                  return true
                }
              }
            })
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tags-container {
  margin: 1rem 0.33rem 0.33rem 0.33rem;
  padding: 0.33rem 0.66rem;
  border: 1px solid #F0F0F0;
  background: #F8F8F8;
}
</style>
