import { api } from '@/global/services/api'
import L from 'leaflet'

const commonActiveIconDataObject = {
  iconSize: [12, 12],
  iconAnchor: [6, 6]
}

const commonInactiveIconDataObject = {
  iconSize: [12, 12],
  iconAnchor: [6, 6]
}

const state = {
  items: [],
  dropDownValues: [],
  permissions: [],
  mapItems: [],
  cityDistricts: [],
  historyMapItems: [],
  pagination: {
    count: 0,
    currentPage: 1,
    perPage: 5,
    totalPages: 1,
    total: 0
  },
  active: true,
  min_lat: false,
  min_lon: false,
  max_lat: false,
  max_lon: false,
  table: false,
  statuses: [],
  from: 0,
  to: 0,
  address: '',
  consentNumber: '',
  year: '',
  sort: {},
  filters: [],
  icons: {
    green: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'green_icon circle'
    }),
    yellow: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'yellow_icon circle'
    }),
    orange: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'orange_icon circle'
    }),
    red: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'red_icon circle'
    }),
    gray: L.divIcon({
      ...commonActiveIconDataObject,
      className: 'gray_icon circle'
    }),
    white: L.divIcon({
      ...commonInactiveIconDataObject,
      className: 'white_icon circle'
    })
  }
}

const getters = {
  list: state => state.items,
  permissions: state => state.permissions,
  pagination: state => state.pagination,
  icons: state => state.icons,
  filters: state => state.filters,
  mapItems: state => state.mapItems,
  historyMapItems: state => state.historyMapItems,
  cityDistricts: state => state.cityDistricts,
  sort: state => state.sort,
  dropDownValues: state => state.dropDownValues
}

const actions = {

  async getIcons ({ commit, state }, options) {
    try {
      const params = {
        min_lat: options.min_lat,
        min_lon: options.min_lon,
        max_lat: options.max_lat,
        max_lon: options.max_lon,
        from: options.from,
        to: options.to,
        active: options.active,
        statuses: options.statuses.toString(),
        consentNumber: options.consentNumber ?? '',
        address: options.address ?? '',
        year: options.year ?? '',
        cityDistricts: options.cityDistricts,
        dateKey: options.dateKey ?? ''
      }

      const {
        data
      } = await api('road-maintenance').get('excavation-order-icons', params)
      if (options.min_lat && options.min_lon && options.max_lat && options.max_lon) {
        commit('SET_HISTORY_MAP_ITEMS', data)
      }
      else {
        commit('SET_MAP_ITEMS', data)
      }
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetch ({ commit, state }, options) {
    try {
      const params = {
        pagination: options.pagination,
        filters: options.filters,
        company_scope_id: options.company_scope_id,
        active: options.active,
        from: options.from,
        to: options.to,
        sort: options.sort,
        table: options.table,
        statuses: options.statuses.toString(),
        consentNumber: options.consentNumber,
        address: options.address,
        year: options.year,
        cityDistricts: options.cityDistricts,
        dateKey: options.dateKey
      }
      const {
        data,
        pagination,
        sort
      } = await api('road-maintenance').get('excavation-orders', params)

      commit('SET_ITEMS', data)
      commit('SET_PAGINATION', pagination)
      commit('SET_SORT', sort)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchCityDistricts ({ commit, state }, options) {
    try {
      const { data } = await api('locations').get('locations/city-districts', { withChildren: true })
      commit('SET_CITY_DISTRICTS', data)
    }
    catch (e) {
      console.dir(e)
    }
  },

  async fetchDropDownValues ({ commit, dispatch }) {
    try {
      const data = await api('road-maintenance').get('excavation-order/tabs-drop-down-values')

      commit('SET_DROPDOWN_VALUES', data)
    }
    catch (e) {
      console.dir(e)
    }
    finally {
    }
  },

  async fetchPermissions ({ commit, dispatch }) {
    try {
      const permissions = await api('road-maintenance').get('excavation-service/permissions')

      commit('SET_PERMISSIONS', permissions)
    }
    catch (e) {
      console.dir(e)
    }
  }
}

const mutations = {
  SET_ITEMS: (state, payload) => (state.items = payload),
  SET_CITY_DISTRICTS: (state, payload) => (state.cityDistricts = payload),
  SET_PAGINATION ({ pagination }, payload) {
    const { count, currentPage, perPage, totalPages, total } = payload
    pagination.count = count || 0
    pagination.currentPage = currentPage || 1
    pagination.perPage = perPage || 0
    pagination.totalPages = totalPages || 1
    pagination.total = total || 0
  },
  SET_SORT: (state, payload) => (state.sort = payload),
  SET_FILTERS: (state, payload) => (state.filters = payload),
  SET_MAP_ITEMS: (state, payload) => (state.mapItems = payload),
  SET_HISTORY_MAP_ITEMS: (state, payload) => (state.historyMapItems = payload),
  SET_DROPDOWN_VALUES: (state, payload) => (state.dropDownValues = payload),
  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
