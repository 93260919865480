<template>
  <div
    id="mainWrapper"
    class="fill-height"
  >
    <Loading v-if="showLoading" />
    <component
      :is="layout"
      v-else
    >
      <router-view :key="$route.fullPath" />
      <ShowNotifications v-if="!isNotFound" />
    </component>
  </div>
</template>

<script>
import Loading from '@/global/views/LoadingView'
import DefaultLayout from '@/global/layouts/DefaultLayout'
import CenteredLayout from '@/global/layouts/CenteredLayout'
import LoginLayout from '@/global/layouts/LoginLayout'
import ShowNotifications from '@/modules/base-module/notifications/components/ShowNotifications'
import { api } from './global/services/api'

export default {
  name: 'App',

  components: {
    Loading,
    DefaultLayout,
    CenteredLayout,
    LoginLayout,
    ShowNotifications
  },

  data () {
    return {
      validRouteNames: [
        'Login',
        'ForgotPassword',
        'PasswordReset',
        'SetPassword',
        'Cookies'
      ]
    }
  },

  computed: {
    isNotFound () {
      return this.$route.name === 'NotFound'
    },

    isMaintenanceMode () {
      return this.$route.name === 'MaintenanceMode'
    },

    showLoading () {
      return (
        !this.isNotFound &&
        !this.isMaintenanceMode &&
        !this.validRouteNames.includes(this.$route.name) &&
        this.$store.getters['base/config/isInitialized'] === false
      )
    },

    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },

  created () {
    this.setVhProperty()

    // call ping method every 30 min to keep session alive
    setInterval(function () {
      api('base').get('ping')
    }, 1800000
    )
    // We also need to recalculate `--vh` property on window resize event
    // (user rotates the device or browser goes to full screen mode)
    window.addEventListener('resize', this.setVhProperty)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setVhProperty)
  },

  methods: {
    /**
     * This function is needed because mobile browsers do not calculate viewport height as expected.
     * They are configured to include any system or browser toolbars in the height,
     * that is why is any fixed content displayed outside of the viewport.
     */
    setVhProperty () {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
}
</script>

<style lang="scss">
body {
  min-height: calc(var(--vh, 1vh) * 100);
}

.v-btn.no-hover-background::before {
  background-color: transparent !important;
}

.theme--light.v-data-table.table-stripped tbody tr:nth-of-type(even) {
  background-color: rgba(248,249,253,255);
}

.theme--dark.v-data-table.table-stripped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .15);
}

// Tracking history
.tracking-history-table {
  position: relative;
  td, th {
    padding: 0.5rem !important;
  }
  td {
    font-size: 0.75rem !important;
  }
}
</style>

<style lang="css">
@import "global/assets/css/style.css";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
@import url('https://fonts.cdnfonts.com/css/satoshi');

@font-face {
  font-family: 'DrukText-BoldItalic';
  src: local('DrukText-BoldItalic'),
  url(./fonts/DrukText/DrukText-BoldItalic.otf) format('opentype');
}
@font-face {
  font-family: 'DrukTextCyr-Medium';
  src: local('DrukTextCyr-Medium'),
  url(./fonts/DrukText/DrukTextCyr-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'DrukWide-SuperItalic';
  src: local('DrukWide-SuperItalic'),
  url(./fonts/DrukText/DrukWide-SuperItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'SairaExtraCondensed-Bold';
  src: local('SairaExtraCondensed-Bold'),
  url(fonts/Saira/SairaExtraCondensed-Bold.ttf) format('truetype');
}
</style>
